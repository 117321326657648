:root {
    --tr-primary-color: #09173A;
    --tr-secondary-color: #434E93;
    --tr-light-color: #D7DDFF;
    --tr-neutral-color: #EDEDED;
    --sidebar-expanded: 236px;
}

body {
    margin: 0px;
}

#root {
    padding-top: 6px;
    margin: 0px;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.toolbar {
    width: 100%;
    height: 56px;
    background-color: var(--tr-neutral-color);
    display: flex;
    flex-direction: row;
}

.wrapper-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.tarot-sidebar, .tarot-sidebar.collapsed:hover {
    flex: 1 0 var(--sidebar-expanded);
    height: 100%;
    background-color: var(--tr-primary-color);
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tarot-sidebar.collapsed {
    flex: 1 0 56px;
    height: 100%;
    background-color: var(--tr-primary-color);
    transition: 0.3s;
}

/* .tarot-content-client {
    margin-top: 20px;
} */

.tarot-client-tags span {
    display: inline-block;
    font-size: 0.7rem;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: var(--tr-light-color);
    border-radius: 12px;
    margin: 5px;
    white-space: nowrap;
}

.tarot-client-tags svg {
    cursor: pointer;
}

.content .tarot-client-comments {
    font-size: 0.8rem;
    margin-bottom: 20px;
}

.expand-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.expand-button svg {
    height: 18px;
    width: auto;
    color: var(--tr-light-color);
}

.menu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sidebar-profile-avatar {
    background-color: var(--tr-light-color);
    border-radius: 500px;
    border-color: var(--tr-primary-color);
    border-width: 1px;
    border-style: solid;
    padding: 1px; 
    width: 36px;
    height: 36px;
    margin-top: 10px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-profile-avatar img {
    width: 32px;
    height: auto;
    border-radius: 50%;
}

.tarot-sidebar .menu {
    height: 62vh;
    overflow-y: scroll;
}

.tarot-sidebar .angle {
    margin-left: auto;
    margin-right: 0px;
    height: 14px;
}

.tarot-sidebar .menu::-webkit-scrollbar {
    width: 2px;
}
  
.tarot-sidebar .menu::-webkit-scrollbar-track {
    background: var(--tr-primary-color);
}
  
.tarot-sidebar .menu::-webkit-scrollbar-thumb {
    background: #888;
}

.tarot-sidebar .menu::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.tarot-sidebar .submenu {
    margin-left: 56px;
    display: block;
}

.tarot-sidebar .submenu.hidden {
    display: none;
}

.tarot-sidebar .submenu-item {
    display: block;
    color: var(--tr-light-color);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    margin-right: 20px;
    margin-top: 4px;
    white-space: nowrap;
    padding-bottom: 16px;
    cursor: pointer;
}

.tarot-sidebar .menu-item-text {
    display: block;
    color: var(--tr-light-color);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    margin-right: 20px;
    margin-top: 4px;
    white-space: nowrap;
}

.tarot-sidebar .tarot-demo {
    position: absolute;
    bottom: 0px;
}

.tarot-sidebar .tarot-demo svg {
    display: none;
}

.tarot-sidebar .tarot-demo span {
    display: block;
    background-color: var(--tr-light-color);
    font-size: 0.8rem;
    color: var(--tr-primary-color);
    width: 180px;
    min-height: 20px;
    margin: 30px;
    padding: 10px;
}

.tarot-sidebar.collapsed .angle {
    display: none;
}

.tarot-sidebar.collapsed:hover .angle {
    display: block;
}

.tarot-sidebar.collapsed .menu-item-text {
    display: none;
}

.tarot-sidebar.collapsed .submenu-item {
    display: none;
}

.tarot-sidebar.collapsed:hover .menu-item-text {
    display: block;
}

.tarot-sidebar.collapsed:hover .submenu-item {
    display: block;
}

.tarot-sidebar.collapsed .tarot-demo svg {
    color: yellow;
    margin-bottom: 30px;
    display: block;
}

.tarot-sidebar.collapsed .tarot-demo span {
    display: none;
}

.tarot-sidebar.collapsed:hover .tarot-demo svg {
    display: none;
}

.tarot-sidebar.collapsed:hover .tarot-demo span {
    display: block;
}

.menu-item svg {
    height: 24px;
    width: auto;
    color: var(--tr-light-color);
    margin: 16px;
}

.tarology-logo {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex: 1 0 var(--sidebar-expanded);
}

.tarology-logo img {
    height: 44px;
    width: auto;    
}

.toolbar-burger, .toolbar-ellipsis {
    display: none;
    align-items: center;
    justify-content: center;
}

.toolbar-burger {
    margin-left: 16px;
}

.toolbar-ellipsis {
    margin-right: 16px;
}

.toolbar-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.toolbar-left, .toolbar-right {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toolbar-right {
    justify-content: right;
    padding-right: 16px;
}

.toolbar-dropdown {
    display: flex;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    padding-bottom: 0px !important;
}

.toolbar-dropdown button {
    background-color: transparent;
    color: var(--tr-primary-color);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border: none;
}

.toolbar-dropdown button:disabled {
    background-color: transparent;
    color: #888;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border: none;
}

.toolbar-dropdown.btn {
    background-color: transparent;
    color: var(--tr-primary-color);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border: none;
}

.toolbar-dropdown span {
    color: var(--tr-primary-color);
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    max-width: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.toolbar-dropdown .dropdown-menu {
    max-height: 80vh;
    overflow-y: auto;
}

.toolbar-dropdown svg {
    width: 18px;
    height: auto;
    margin-left: 6px;
}

.tarot-deal-client {
    width: 160px;
    margin-top: 5px;
}

.tarot-tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    top: 45px;
    margin-left: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 0.8rem;
    font-weight: 600;
    color: white;
    background-color: var(--tr-primary-color);
    padding: 8px;
    border-radius: 6px;
    opacity: 0.8;
}

.tarot-tooltip::after {
    content: " ";
    position: absolute;
    bottom: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color:  transparent transparent black transparent;
}

.tarot-tooltip.right {
    margin-left: -30px;
}

.tarot-tooltip.right::after {
    left: 65%;
}

.toolbar-dropdown:hover .tarot-tooltip {
    visibility: visible;
}

.toolbar-button {
    cursor: pointer;
}

.toolbar-button.badge {
    margin-top: 12px;
}

.toolbar-button.nonbadge {
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.toolbar-button svg {
    width: auto;
    height: 18px;
    margin: 8px;
    color: var(--tr-primary-color);
}

.toolbar-button:hover .tarot-tooltip {
    visibility: visible;
}

.toolbar-button[disabled] {
    pointer-events: none;
    cursor: unset;
}

.toolbar-button[disabled] svg {
    color: #AAA;
    opacity: 0.8;
}

.toolbar-button.disabled {
    pointer-events: none;
    cursor: unset;
}

.toolbar-button.disabled svg {
    color: #AAA;
    opacity: 0.8;
}

.toolbar-input {
    padding: 5px;
    border-radius: 6px;
    border-width: 0.5;
}

.toolbar-input {
    width: 180px;
    padding-right: 30px;
}

.toolbar-input-icon {
    z-index: 1;
    margin-left: -30px;
}

.toolbar-input-icon svg {
    color: var(--tr-primary-color);
    width: 16px;
    height: 16px;
}

#work-area {
    padding: 0px;
    padding-bottom: 40px;
    margin: 20px;
    width: 100%;
    /* height: 100%; */
}

.deal-tree {
    background-color: var(--tr-neutral-color);
    width: 330px;
    flex: 0 0 330px;
    padding: 16px;
    display: flex;
    height: calc(100% - 12px);
}

.deal-tree .tree-content {
    background-color: white;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.deal-tree .tree-header {
    border-top-right-radius: 10px;
    background-color: var(--tr-primary-color);
    border-top-left-radius: 10px;
    padding: 10px;
    text-align: center;
    color: white;
    font-weight: 600;
}

.deal-tree .body {
    padding: 10px;
    height: 100%;
    font-size: 0.8rem;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.deal-tree .tree-level-0 {
    display: flex;
    flex-direction: row;
}

.deal-tree .tree-chevron {
    margin-right: 8px;
}

.deal-tree .tree-line {
    width: 250px;
}

.deal-tree .deal-type {
    font-weight: 600;
    cursor: pointer;
}

.deal-tree .deal-tree-title {
    padding: 5px;
}

.deal-tree .deal-tree-title:hover {
    background-color: var(--tr-secondary-color);
    color: white;
    padding: 5px;
    border-radius: 7px;
}

.deal-tree .deal-client {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.7rem;
    font-style: italic;
    cursor: pointer;
}

.deal-tree .tree-level-1 {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
    padding: 3px;
}

.deal-tree .tree-level-1:hover {
    background-color: var(--tr-secondary-color);
    color: white;
    border-radius: 7px;
}

.deal-tree .tree-extra {
    background-color: unset;
    border: unset;
}

.deal-tree .tree-extra.active {
    background-color: var(--tr-light-color);
    border-radius: 8px;
    border: var(--tr-primary-color);
    border-style: dashed;
    border-width: 0.5px;
}

.deal-tree .tree-level-1-5 {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
    padding: 3px;
}

.deal-tree .tree-level-1-5:hover {
    background-color: var(--tr-secondary-color);
    color: white;
    border-radius: 7px;
}

.deal-tree .tree-level-2 {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
    margin-left: 22px;
    padding: 3px;
}

.deal-tree .tree-level-2:hover {
    background-color: var(--tr-secondary-color);
    color: white;
    border-radius: 7px;
}

.deal-tree .tree-level-2-5 {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
    margin-left: 22px;
    padding: 3px;
}

.deal-tree .tree-level-2-5:hover {
    background-color: var(--tr-secondary-color);
    color: white;
    border-radius: 7px;
}

.deal-tree .tree-level-3 {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
    margin-left: 38px;
    padding: 3px;
}

.deal-tree .tree-level-3:hover {
    background-color: var(--tr-secondary-color);
    color: white;
    border-radius: 7px;
}

.tree-level-1 .tree-chevron {
    width: 12px;
    margin-right: 8px;
}

.tree-level-1-5 .tree-chevron {
    width: 12px;
    margin-right: 8px;
}

.tree-level-2 .tree-chevron {
    width: 12px;
    margin-right: 8px;
}

.tree-level-2-5 .tree-chevron {
    width: 12px;
    margin-right: 8px;
}

.tree-level-1 .position {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--tr-primary-color);
    color: white;
    font-weight: 600;
}

.tree-level-1-5 .extra-deal {
    width: 18px;
    margin-left: 6px;
    margin-right: 4px;
}

.tree-level-2 .extra-deal {
    width: 18px;
    margin-left: 6px;
    margin-right: 4px;
}

.tree-level-2-5 .position {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--tr-primary-color);
    color: white;
    font-weight: 600;
}

.tree-level-3 .position {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--tr-primary-color);
    color: white;
    font-weight: 600;
}

.tarot-badge {
    background-color: blue;
    color: white;
    font-size: 0.6rem;
    font-weight: 600;
    position: relative;
    top: -30px;
    right: -20px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.tarot-badge.inactive {
    background-color: transparent;
    color: transparent;
}

.tarot-badge.active {
    background-color: blue;
    color: white;
}

.tarology-comment-add {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}

.tarology-comment-add svg {
    cursor: pointer;
    width: 24px;
    height: auto;
}

.line.selected {
    font-weight: 600;
    text-decoration: underline;
}

.line.question-1 {
    padding: 3px;
    padding-left: 50px;
    margin-bottom: 2px;
    font-style: italic;
    cursor: pointer;
}

.line.question-1:hover {
    background-color: var(--tr-secondary-color);
    color: white;
    border-radius: 7px;
}

.line.question-2 {
    padding: 3px;
    padding-left: 70px;
    margin-bottom: 2px;
    font-style: italic;
    cursor: pointer;
}

.line.question-2:hover {
    background-color: var(--tr-secondary-color);
    color: white;
    border-radius: 7px;
}

.tarot-version {
    text-align: center;
    position: absolute;
    bottom: 20px;
    font-size: 0.7rem;
    color: white;
}

@media screen and (max-width: 720px) {
    .tarot-sidebar {
        display: none;
    }

    .tarot-sidebar.responsive {
        display: flex;
        z-index: 1;
        position: absolute;

    }

    .toolbar-ellipsis {
        display: flex;
    }    

    .toolbar-burger {
        display: flex;
    }

    .toolbar-menu {
        display: none;
        background-color: white;
        z-index: 1;
        top: 52px;
    }

    .toolbar-menu.responsive {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0px;
    }

    .toolbar-menu.responsive .toolbar-left, .toolbar-right {
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }

    .toolbar-menu .toolbar-dropdown div {
        background-color: aquamarine; 
    }

    .toolbar-menu.responsive .toolbar-dropdown span { 
        max-width: 100%;
    }

    .toolbar-menu.responsive .toolbar-dropdown { 
        border-bottom-width: 0.5;
        border-bottom-style: solid;
        width: 100%;
        padding: 8px;
        margin: 0px;
    }

    .toolbar-menu.responsive .toolbar-button {
        display: flex;
        flex-direction: row;
        border-bottom-width: 0.5;
        border-bottom-style: solid;
        width: 100%;
        padding: 8px;
        margin: 0px;
    }

    .toolbar-menu.responsive .toolbar-button svg {
        margin: 0px;
    }

    .toolbar-menu.responsive .button-text-responsive {
        visibility: visible;
        position: relative;
        top: 0px;
        margin-left: 10px;
        padding: 0px;
        color: var(--tr-primary-color);
        border: none;
        background-color: transparent;
    }

    .toolbar-menu.responsive .button-text-responsive::after {
        content: none;
    }

}