.wheel {
  display: none;
  position: absolute;
  top: var(--y);
  left: var(--x);
  width: 400px;
  height: 400px;    
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
  opacity: 0.9;
  transition-timing-function: cubic-bezier(0, 0.5, 0.5, 1.5);
  z-index: 3;

  &.child {
    top: 300px;
  }

  &.visible {
    display: block;
  }

  .segment {
    color: black;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 50%;
    /* transition-property: transform, opacity, background-image; */
    transition: all 0.3s ease-in-out;
    opacity: 0.8;
    transform-origin: 50% 100%;
    transform: scale(1) rotate(var(--rotate));
    transition-timing-function: cubic-bezier(0, 0.5, 0.5, 1.5);
    transition-duration: 0.3s;
    background-image: radial-gradient(circle at 50% 100%, transparent, transparent 29.5%, var(--color-border) 30%, var(--color-border) 30.5%, var(--color) 31%, var(--color) 50%, var(--color-border) 50.25%, var(--color-border) 51.5%, transparent 51.75%, transparent);
  
    img {
      width: 40px;
      height: 40px;
    }

    &:hover {
      /* background-image: radial-gradient(circle at 50% 100%, transparent, transparent 32.5%, var(--color-border) 33%, var(--color-border) 33.5%, var(--color) 34%, var(--color) 55%, var(--color-border) 55.25%, var(--color-border) 56.5%, transparent 56.75%, transparent); */
      transform: scale(1.1) rotate(var(--rotate));
      transition-timing-function: cubic-bezier(0, 0.5, 0.5, 1.5);
      transition-duration: 0.3s;
      cursor: pointer;
  
      span {
        /* transform: scale(1.1);
        transition-timing-function: cubic-bezier(0, 0.5, 0.5, 1.5);
        transition-duration: 0.3s; */
        cursor: pointer;
        /* top: 28%;
        font-size: 1.9rem; */
      }  
    }
  
    &:active {
      /* background-image: radial-gradient(circle at 50% 100%, transparent, transparent 32.5%, var(--color-border-active) 33%, var(--color-border-active) 33.5%, var(--color-active) 34%, var(--color-active) 55%, var(--color-border-active) 55.25%, var(--color-border-active) 56.5%, transparent 56.75%, transparent); */
      transform: scale(1.1) rotate(var(--rotate));
      transition-timing-function: cubic-bezier(0, 0.5, 0.5, 1.5);
      transition-duration: 0.3s;
      opacity: 1;
      cursor: pointer;
  
      span {
        cursor: pointer;
        color: white;
        /* top: 28%;
        font-size: 1.9rem; */
      }  
    }
  
    span {
      position: fixed;
      top: 34%;
      display: flex;
      justify-content: center;
      transform-origin: 0% 100%;
      width: 100%;
      height: 100%;
      font-size: 1.7rem;
    }
  }

  .wheel-label {
    width: 50%;
    height: 50%;
    border-radius: 50%;
    background-color: white;
    margin-top: 25%;
    margin-left: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .label-h1 {
      font-size: 1.8rem;
      margin-top: 20px;
      color: #555555;  
      position: fixed;
      top: 35%;
    }

    .label-h2 {
      line-height: 1.8rem;
      font-size: 1.4rem;
      font-weight: 800;
      margin-bottom: 20px;
      color: #555555;
      width: 40%;
      text-align: center;
      color: #555555;
      position: fixed;
      top: 50%;
    }
  }
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  background-color: bisque;
  
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  background-color: aqua;
}

@for $i from 1 through 20 {
  .segment {
    &:nth-child(#{$i}) {
      --color: hsl(#{($i - 1) * 20}deg, 56%, 60%);
      --color-border: hsl(#{($i - 1) * 20}deg, 56%, 40%);
      --color-active: hsl(#{($i - 1) * 20}deg, 26%, 60%);
      --color-border-active: hsl(#{($i - 1) * 20}deg, 26%, 40%);
    }
  }
}