@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@800&display=swap');
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

:root {
    --tr-primary-color: #09173A;
    --tr-secondary-color: #434E93;
    --tr-light-color: #D7DDFF;
}

#tarology-logo {
    cursor: pointer;
}

#tarology-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.icon-overlay {
    font-family: Nunito, sans-serif;
    font-weight: 800;
    font-size: 1.5rem;
    color: #FFFFFF;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.btn {
    border-radius: 5px;
    border-color: var(--tr-primary-color);
    background-color: var(--tr-primary-color);
}

.btn:hover {
    border-radius: 5px;
    border-color: var(--tr-secondary-color);
    background-color: var(--tr-secondary-color);
}

.btn:focus {
    border-color: var(--tr-primary-color);
    background-color: var(--tr-primary-color);
}

.btn:disabled {
    border-radius: 5px;
    border-color: var(--tr-secondary-color);
    background-color: var(--tr-secondary-color);
}

#dropdown-item-button.dropdown-toggle.btn.btn-primary {
    border-color: var(--tr-primary-color);
    background-color: var(--tr-primary-color);
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {    border-color: var(--tr-primary-color);
    background-color: var(--tr-secondary-color);
}

.nkp-box-shadow {
    background: #FFFFFF;
    box-shadow: 2px 6px 10px 3px rgba(0, 85, 121, 0.5);
    border-radius: 15px;
}

.nkp-search-box {
    border-radius: 50px;
    border-color: #0081C9;
    border-style: solid;
    border-width: 1px;
}

.nkp-textarea {
    border-radius: 15px;
    border-color: #0081C9;
    border-style: solid;
    border-width: 1px;
}

.nkp-textarea-prepend {
    display: flex;
    align-items: flex-start;
}

.nkp-textarea-invalid {
    background-color: #ffdbd9;
    border-radius: 15px;
    border-color: #FF0000;
    border-style: solid;
    border-width: 1px;
}

.nkp-search-box-invalid {
    background-color: #ffdbd9;
    border-radius: 50px;
    border-color: #FF0000;
    border-style: solid;
    border-width: 1px;
}

.nkp-checkbox-invalid {
    background-color: #ffdbd9;
    border-color: #FF0000;
}

div#nkp-support div {
    margin-top: 5px;
}

div#nkp-support label {
    margin-top: 3px;
}

.table thead th {
    border-color: #FFFFFF;
    color: #FFFFFF;
    background-color: var(--tr-primary-color);
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 85, 121, 0.05);
}

.nkp-dark-box-1 {
    background: rgba(0, 0, 0, .8);
}

.nkp-document-plate {
    background: #C2E3F3;
    border-radius: 15px;
    min-height: 5em;
    font-size: small;
    padding: 15px;
    margin: 15px;
}

.nkp-kennel-plate-kennel {
    background: #FFFFFF;
    box-shadow: 2px 6px 15px 5px rgba(0, 85, 121, 0.5);
    border-radius: 15px;
    min-width: 250px;
    font-size: medium;
}

.nkp-kennel-plate-prefix {
    background: #C2E3F3;
    box-shadow: 2px 6px 15px 5px rgba(0, 85, 121, 0.5);
    min-width: 250px;
    border-radius: 15px;
    font-size: medium;
}

.nkp-dog-plate {
    background: #FFFFFF;
    border-radius: 15px;
    border-width: 1px;
    border-color: #0081C9;
    border-style: solid;
    font-size: medium;
}

.custom-file-upload {
    background-color: #0081C9;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 50px;
}

.nkp-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}

.nkp-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    height: calc(1.5em + 0.75rem);
    line-height: 1.5;
    content: "Browse";
    border-left: inherit;
    background-color: #0081C9;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 50px;
}

.nkp-file-label-invalid {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}

.nkp-file-label-invalid::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    height: calc(1.5em + 0.75rem);
    line-height: 1.5;
    content: "Browse";
    border-left: inherit;
    background-color: #dc3545;
    color: white;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 50px;
}

.nkp-date-capital {
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 56px;
    line-height: 87px;
    text-align: center;
    color: #0081C9;
}

.nkp-date-rest {
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 49px;
    text-align: center;
    color: #0081C9;
}

.nkp-sports-plate {
    background: #FFFFFF;
    border-radius: 15px;
    border-color: #0081C9;
    border-width: 1px;
    border-style: solid;
    font-size: medium;
}

.nkp-sports-plate-active {
    background: #FFFFFF;
    border-radius: 15px;
    border-color: #0081C9;
    border-width: 1px;
    border-style: solid;
    box-shadow: 2px 6px 15px 5px rgba(0, 85, 121, 0.5);
    font-size: medium;
}

.nkp-sports {
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 49px;
    text-align: center;
    color: #0081C9;
}

.nkp-sports-overlay {
    position: absolute;
    min-width: 350px;
    min-height: 200px;

    background: rgba(194, 227, 243, 0.9);
    box-shadow: 2px 6px 15px 5px rgba(0, 85, 121, 0.5);
    border-radius: 15px;

    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;

    z-index: 10;
}

.nkp-sports-overlay-date {
    font-family: Nunito, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    text-align: center;
    color: #0081C9;
}

.nkp-board-name {
    font-size: small;
    font-weight: 900;
    text-align: center;
}

.wrapper {
/*    display: flex;
    align-items: stretch;
    background-color: #B30000;*/
    display: flex;
    margin-top: -10px;
    position: relative;
    min-height: 100%;
/*    overflow-y: scroll;*/
}

html, body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
    height: 100%;
    margin: 0px;    
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.nav {
    align-content: flex-start;
}

.sidebar {
    background: #00929B;
    color: #fff;
    transition: all 0.3s;
    min-width: 300px;
    max-width: 300px;
    min-height: 100vh;
    margin-left: -230px;
}

.sidebar-expanded {
    background: #00929B;
    color: #fff;
    transition: all 0.3s;
    min-width: 300px;
    max-width: 300px;
    min-height: 100vh;
    margin-left: 0;
}

.sidebar-collapse-expanded {
    position: absolute;
    left: 275px;
    top: 15px;
}

.sidebar-collapse {
    position: absolute;
    left: 45px;
    top: 20px;
}

.content {
    /* padding-left: 20px; */
    /* padding-bottom: 40px; */
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: white;
}

.tarot-clients-table, .tarot-clients-paging {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 24px);
}

.tarot-clients-table, td {
    white-space: nowrap;
}

#customer-profile {
    margin-left: 20px;
    overflow-y: scroll; 
}

#userpic {
    margin-top: 15px;
    margin-right: 30px;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

#userpic img {
    min-width: 40px;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    border-radius: 50px;
}

#userpic-small {
    width: 50px;
    height: 50px;
    margin-left: 240px;
    margin-top: 75px; 
}

#userpic-small img {
    border-style: solid;
    border-color: white;
    border-width: 2px;
}

#username {
    width: 300px;
    margin-top: 10px;
}

#username center {
    font-size: small;
    font-weight: bold;
}

#usermail {
    width: 300px;
}

#usermail center {
    font-size: small;
    font-style: italic;
}

#logout {
    margin-top: 10px;
    width: 300px;
}

#logout center {
    font-size: small;
    font-weight: bold;
}

.sidebar-menu {
    margin-top: 20px;
    margin-left: 20px;
    font-size: large;
    font-weight: 800;
    color: #FFFFFF;
}

.menu-icon-expanded {
    width: 30px;
    padding-right: 10px;
}

.menu-item-expanded {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.menu-icon {
    margin-left:230px;
    margin-bottom: 20px;
}

#taro-dropdown {
    border-color: var(--tr-primary-color);
    border-style: solid;
    border-width: 1px;  
}

#work-area, #work-area-extra {
    margin: 20px;
    border-radius: 15px;
    background-color: var(--tr-light-color);
}

.bg-dark {
    background-color: var(--tr-primary-color);
}

#tarot-menu a {
    background-color: transparent;
    border: none;
    padding: 5px;
}

#tarot-menu .submenu span::before {
    font-size: large;
    font-weight: 600;
    content: "•"; 
    color: white;
    margin-right: 8px;
}

#tarot-menu .submenu span {
    font-size: medium;
    font-weight: 600;
    color: white;
}

#tarot-menu a div {
    font-size: medium;
    font-weight: 600;
}

#tarot-menu-collapsed {
    background-color: transparent;
    color: white;
    margin-left: 210px;
    margin-top: 15px;
}

#tarot-menu-collapsed .btn div {
    border: solid;
    border-radius: 5px;
    border-color: white;
    border-width: 1px;
    padding: 8px;
    margin: 0px;
    width: 30px;
    height: 35px;
}

#tarot-menu-collapsed a {
    background-color: transparent;
    border: none;
    padding: 5px;
}

#tarot-submenu-collapsed {
    position: absolute;
    left: 65px;
    top: 210px;
    font-size: large;
    font-weight: 600;
    content: "•"; 
    color: white;
    margin-right: 8px;
}

#tarot-menu-collapsed .submenu span {
    font-size: medium;
    font-weight: 600;
    color: white;
}

#tarot-menu-collapsed a div {
    font-size: medium;
    font-weight: 600;
}

.tarot-clients-table-bed {
    flex: 1 1 1px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100% - 140px);
}

.tarot-content {
/*    margin-left: 70px;*/
    /* margin-top: 20px;  */
    /* margin-right: 40px; */
    /* margin-bottom: 20px; */
    display: flex;
    /* flex-direction: column; */
    flex-grow: 1;
    max-height: 100%;
}

.tarot-content-expanded {
    /*    margin-left: 290px;*/
        margin-top: 70px;
        display: flex;
        flex-grow: 1;
    }
    
    
.tarot-content-client {
    /*    margin-left: 70px;*/
/*     margin-top: 70px;
 */    display: flex;
    flex-grow: 1;
    transition: all, 0.3s, ease;
    height: 100%;
    padding-bottom: 96px;
}
    
.tarot-content-client-expanded {
/*    margin-left: 290px;*/
    margin-top: 70px;
    display: flex;
    flex-grow: 1;
    transition: width, left, right, 0.3s;
}

.tarot-clients {
    width: 100%;
    margin-top: 20px;
    padding-right: 20px;
    overflow-x: hidden;
}

.tarot-navbar {
    background-color: var(--tr-primary-color) !important;   
    height: 70px;
}

#tarot-brand {
    text-align: center;
    color: white;
    font-weight: 800;
    font-size: x-large;
    max-height: 50px;
    width: auto;
}

#tarot-extras-header {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-right: 30px;
    border-color: #FFFFFF;
    color: #FFFFFF;
    background-color: var(--tr-primary-color);
    font-weight: 600;
    padding: 10px;
}

#tarot-meaning {
    margin-right: 30px;
}

#tarot-logout {
    color: white;
    margin-left: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.tarot-dropdown button {
    width: 100%;
}

.tarot-sex-dropdown button {
    margin-left: 10px;
    width: auto;
}

.tarot-button {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
}

.tarot-button-red {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #CC0000;
    border-color: red;
}

.tarot-button-red:hover {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #FF8888;
    border-color: #FF8888;
}

.tarot-button-red:disabled {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #FF8888;
    border-color: #FF8888;
}

.tarot-text {
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    border-radius: 5px;
    border-color: var(--tr-primary-color);
    border-width: 1px;
    border-style: solid;
}

.strike {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
}

.strike > span {
    position: relative;
    display: inline-block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
    font-size: 10px;
    line-height: 10px;
    padding-top: 10px;
    background: #FFF;
}

.strike:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba(24,92,57,1) 0%,rgba(24,92,57,0) 100%);
}

.tarot-client-deal-bed {
    position: relative;
}

.client-trash-bin {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.client-export-pdf {
    position: absolute;
    right: 40px;
    bottom: 10px;
}

.client-export-pdf svg:hover {
    color: var(--tr-secondary-color);    
}

.tarot-spinner-overlay {
    position: absolute;
    display: flex;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 900;
    width: 100%;
    height: 100%;
    margin-left: 3rem;
    margin-right: 3rem;
    padding-right: 80px;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

.tarot-spinner-overlay .spinner-border {
    margin-right: 0.5rem;
}

.tarot-spinner-overlay .text-dark {
    color: var(--tr-primary-color) !important;
}


.client-trash-bin svg {
    margin-left: 10px !important;
}

.client-trash-bin svg:hover {
    color: var(--tr-secondary-color);
}

div[contenteditable="true"] {
    border: 1px dashed var(--tr-primary-color);
}

.tarot-red-button {
    background-color: #CC0000;
    border-color: red;
}

.tarot-red-button:hover {
    background-color: #FF8888;
    border-color: #FF8888;
}

.tarot-red-button:disabled {
    background-color: #FF8888;
    border-color: #FF8888;
}

.tarot-red-button:active {
    background-color: #CC0000;
    border-color: red;
}

.tarot-red-button:focus {
    background-color: #CC0000;
    border-color: red;
}

.tarot-white-button {
    background-color: #FFFFFF;
    border-color: var(--tr-primary-color);
    color: var(--tr-primary-color);
/*    font-size: 0.7rem;*/
}

.tarot-white-button:hover {
    background-color: var(--tr-light-color);
    border-color: var(--tr-primary-color);
    color: var(--tr-primary-color);
}

.tarot-client-name {
    font-size: 1.5rem;
    font-weight: 800;
}

.client-tag-edit {
    margin-top: 10px;
}

.client-tag-edit input {
    font-size: 0.8rem;
    border-radius: 15px;
}

.react-datepicker-wrapper {
    width: unset;
    margin-left: 15px;
    margin-top: 2px;
}

.react-datepicker-wrapper input {
    width: 120px;
    padding-bottom: 5px;
    color: #505050;
    padding-top: 6px;
    overflow: visible;
    border: #C0C0C0;
    border-width: 0.5px;
    border-style: solid;
}

.tarot-client-icon-bed {
    position: relative;
    margin-right: 20px;
    /* top: 110px;
    z-index: 999; */
}

.tarot-client-icon-bed svg:hover {
    color: var(--tr-secondary-color);
}

.tarot-client-icon {
    margin-right: 20px;
    cursor: pointer;
}

.tarot-client-datepicker {
    margin-left: 10px;
    border-style: dashed;
    border-width: 1px;
    border-color: var(--tr-primary-color);
}

.tarot-client-field {
    font-size: medium;
    display: inline;
    width: 100%;
    margin-left: 10px;
    margin-bottom: 4px;
}

.tarot-client-deal {
    background-color: unset;
    padding: 10px;
    margin-left: 0px;
    margin-right: 20px;
}

.tarot-client-deal:hover {
    background-color: var(--tr-light-color);
    padding: 10px;
}

.tarot-client-deal div {
    cursor: pointer;
    margin-left: 0px;
    margin-top: 0px;
}

#tarot-deal-client .rbt div .rbt-input-main.form-control.rbt-input {
    border-color: var(--tr-primary-color);
}

#tarot-deal-client .rbt div {
    max-height: 30px;
}

.client-trash-bin {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.card-body blockquote.is-info {
    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 20px;
    background-color: #E8EFF5;
    border-color: #81A6C7;
    color: #006DB3;
    border-style: solid;
    border-width: 1px;
    border-inline-start-width: 55px;
    border-inline-start-color: #006DB3;
    margin: 20px;
    border-radius: 10px;
    border-inline-start-style: solid;
}

.card-body blockquote.is-info:before {
    position: absolute;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    color: white;
    margin-left: -60px;
    margin-top: -7px;
    font-size: 30;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    content: "\270E";
}

.card-body blockquote.is-warning {
    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 20px;
    background-color: #FFE0E0;
    border-color: #FF4D4D;
    color: #B30000;
    border-style: solid;
    border-width: 1px;
    border-inline-start-width: 55px;
    border-inline-start-color: #B30000;
    margin: 20px;
    border-radius: 10px;
    border-inline-start-style: inherit;
}

.card-body blockquote.is-warning:before {
    position: absolute;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    color: white;
    margin-left: -60px;
    margin-top: -7px;
    font-size: 30;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    content: "\26A0";
}

.card-body blockquote.is-success {
    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 20px;
    background-color: #e8f5e9;
    border-color: #81c784;
    color: #1b5e20;
    border-style: solid;
    border-width: 1px;
    border-inline-start-width: 55px;
    border-inline-start-color: #1b5e20;
    margin: 20px;
    border-radius: 10px;
    border-inline-start-style: inherit;
}

.card-body blockquote.is-success:before {
    position: absolute;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    color: white;
    margin-left: -60px;
    margin-top: -7px;
    font-size: 30;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    content: "\2714";
}

blockquote.is-info {
    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 20px;
    background-color: #E8EFF5;
    border-color: #81A6C7;
    color: #006DB3;
    border-style: solid;
    border-width: 1px;
    border-inline-start-width: 55px;
    border-inline-start-color: #006DB3;
    margin: 20px;
    border-radius: 10px;
    border-inline-start-style: inherit;
}

blockquote.is-info:before {
    position: absolute;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    color: white;
    margin-left: -60px;
    margin-top: -7px;
    font-size: 30;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    content: "\270E";
}

blockquote.is-warning {
    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 20px;
    background-color: #FFE0E0;
    border-color: #FF4D4D;
    color: #B30000;
    border-style: solid;
    border-width: 1px;
    border-inline-start-width: 55px;
    border-inline-start-color: #B30000;
    margin: 20px;
    border-radius: 10px;
    border-inline-start-style: inherit;
}

blockquote.is-warning:before {
    position: absolute;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    color: white;
    margin-left: -60px;
    margin-top: -7px;
    font-size: 30;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    content: "\26A0";
}

blockquote.is-success {
    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 20px;
    background-color: #e8f5e9;
    border-color: #81c784;
    color: #1b5e20;
    border-style: solid;
    border-width: 1px;
    border-inline-start-width: 55px;
    border-inline-start-color: #1b5e20;
    margin: 20px;
    border-radius: 10px;
    border-inline-start-style: inherit;
}

blockquote.is-success:before {
    position: absolute;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    color: white;
    margin-left: -60px;
    margin-top: -7px;
    font-size: 30;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    content: "\2714";
}

blockquote.tarot-comment {
    display: block;
    width: 100%;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 20px;
    background-color: var(--tr-light-color);
    border-style: solid;
    border-width: 1px;
    border-color: var(--tr-primary-color);
    /* border-left-width: 55px; */
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
}

blockquote.tarot-comment:before {
    /* position: absolute; */
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    color: white;
    margin-left: -60px;
    margin-top: -10px;
    font-size: 24px;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
    /* content: "\2712"; */
}

#comment .comment-date {
    font-size: x-small;
    font-style: italic;
    text-align: right;
}

#comment .comment-button {
    margin-top: 15px;
    font-size: x-small;
    text-align: right;
    cursor: pointer;
}

#comment .comment-button svg {
    width: 16px;
    height: auto;
}

#birthday {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
}

#birthday .datepicker {
    margin-top: -5px;
}

#birthday .birtday-label {
    min-width: 135px;
}

.tarot-hint {
    display: block;
    width: 100%;
/*    height: 500px;*/
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 20px;
    background-color: var(--tr-light-color);
    border-style: solid;
    border-width: 1px;
    border-color: var(--tr-primary-color);
    margin-bottom: 10px;
    border-radius: 10px;
/*    overflow-y: scroll;
    scrollbar-width: thin;*/

}

#hint-button {
    position: absolute;
    right: 30px;
    border-style: dashed;
    border-width: 1px;
    border-radius: 10px;
    width: 53px;
    height: 53px;
    border-color: rgba(24, 92, 57, .5);
}

#hint-button .tarot-hint-button {
    position: relative;
    left:10px;
    top: 10px;
    color: rgba(24, 92, 57, .5);
}

.tarot-hint-text {
    display: inline-block;
    padding: 10px;
    overflow-y: scroll;
/*    height: 420px;*/
}

.tarot-hint-text span > * {
    font-size: small;
}

.tarot-hint-content {
    font-size: x-small;
}

.tarot-hint-content #text {
    font-size: x-small;
}

.card-header .btn-link {
    color: #FFFFFF;
}

.card-header .btn-link:hover {
    text-decoration: none;
}

.extra-header {
    background-color: var(--tr-primary-color);
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: 600;
}

.extra-text {
    font-size: 1rem;
    padding: 20px;
    border-color: var(--tr-primary-color);
    border-style: solid;
    border-width: 1px;
}

.error-message {
    color: #B30000;
    font-weight: 600;
}

.tarot-email-control {
    display: flex;
    line-height: 2rem;
}

.tarot-email-control b {
    white-space: nowrap;
    flex: 0 0;
}

#floating {
    background-color: azure;
    position: absolute;
    cursor: move;
    user-select: none; 
    display: block;
    background-color: var(--tr-light-color);
    border-style: solid;
    border-width: 1px;
    border-color: var(--tr-primary-color);
    margin-bottom: 10px;
    border-radius: 10px;
    z-index: 1049;
    display: flex;
    flex-direction: column;
}
  
#floating .resizer {
    position: absolute;
    right: -2px;
    bottom: -2px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    cursor: nwse-resize
}
  
#floating .header {
    background-color: var(--tr-primary-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 6px;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: left;
}
  
#floating .header .icon {
    position: absolute;
    top: 7px;
    right: 10px;
    cursor: pointer;
}
  
#floating .body {
    background-color: white;
    height: 100%;
    padding: 10px;
    text-align: left;
    font-size: 0.8rem;
    overflow: auto;
}  

#floating .tarot-question {
    height: unset;
}

#floating .float-buttons-top, .float-buttons-bottom {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
}

#floating .float-button {
    cursor: pointer;
}

#floating .float-button svg {
    width: 20px;
    height: auto;
}

.entity-items-per {
    font-size: 0.9rem;
    background-color: white;
    margin-right: 10px;
    border-color: #dee2e6;
    border-width: 1px;
    border-style: solid;
    height: 32px;
}

.entity-footer {
    font-size: 0.9rem;   
}

.entity-footer .entity-items-per {
    width: unset;
}

.entity-button-place-right {
    position: relative;
    justify-content: flex-end;
    align-items: center;
    top:0px;
    right: 0px;
}

.entity-pagination {
    border-color: #dee2e6;
    border-width: 1px;
    border-style: solid;
    height: 32px;
}

.entity-pagination div {
    font-size: 0.9rem;
    display: inline-block;
    padding: 5px;
    height: 30px;
}

.entity-pagination svg.active {
    cursor: pointer;
}

.entity-pagination svg {
    cursor: unset;
}

.entity-pagination div.entity-pagination-num {
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.entity-pagination div.entity-pagination-filler {
    padding-left: 10px;
    padding-right: 10px;
}

.entity-pagination div > svg {
    margin-top: -5px;
    width: 15px;
    height: 15px;
}

.client-table-header {
    cursor: pointer;
}

.client-table-header span {
    margin-right: 10px;
}

.p-avatar {
    display: flex;
    color: white;
    background-color: var(--tr-secondary-color);
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-top: -5px;
}

.p-avatar-circle {
    border-radius: 50px;
}

.p-avatar-lg {
    font-size: 1.4rem;
    font-weight: 600;
}

.profile-menu {
    position: absolute;
    min-width: 300px;
    color: white;
    background-color: var(--tr-primary-color);
    right: 0px;
    top: 71px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.4s;
}

.profile-menu.inactive {
    visibility: hidden;
    transform: translateY(-170px);
}

.profile-menu.active {
    visibility: visible;
    transform: translateY(0);
}

.profile-menu .menu-item {
    padding: 12px;
}

.profile-menu .menu-item:hover {
    background-color: var(--tr-secondary-color);
}

.profile-menu .menu-divider {
    margin-top: -12px;
    margin-bottom: -12px;
}

.profile-menu hr {
    border-top: 1px solid white;
}

#user-profile-avatar {
    background-color: var(--tr-light-color);
    border-radius: 500px;
    border-color: var(--tr-primary-color);
    border-width: 5px;
    border-style: solid;
    padding: 10px;
    width: 200px;
    height: 200px;
    margin: 30px;
    position: relative;
}

#user-profile-avatar.edit {
    background-color: var(--tr-light-color);
    border-radius: 500px;
    border-color: var(--tr-primary-color);
    border-width: 5px;
    border-style: solid;
    padding: 10px;
    width: 200px;
    height: 200px;
    margin: 30px;
    margin-bottom: 70px;
    position: relative;
}

#user-profile-avatar.edit label {
    border-radius: 5px;
    border-color: var(--tr-primary-color);
    background-color: var(--tr-primary-color);
    color: white;
    padding: 7px;
    font-size: 0.9rem;
    margin-top: 7px;
}

.tarot-user-data-section {
    margin-left: 15px;
    margin-right: 15px;
}

.tarot-profile-content {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    max-height: calc(100% - 60px);
}

#theme-switch {
    border-radius: 5px;
    border-color: var(--tr-primary-color);
    background-color: var(--tr-primary-color);
    color: white;
    padding: 7px;
    font-size: 0.9rem;
    margin-top: 7px;
    margin-right: 10px;
}

#avatar-pic {
    display: none;
}

#user-profile-avatar .avatar-editor {
    margin-left: -15px;
    margin-top: -15px;
}

#user-profile-avatar .edit-avatar {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.profile-section-name {
    display: flex;
    flex-direction: row;
}

.tarot-name-control {
    margin-right: 135px;
    flex: 1 1;
}

.toast.show {
    opacity: 0.8;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 100%;
    color: red;
}

.edit-username {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    cursor: pointer;
}

.field .data .edit-field {
    position: absolute;
    right: 20px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

#user-profile-avatar img {
    width: 170px;
    height: 170px;
    border-radius: 500px;
}

#user-avatar-bed {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#user-profile-avatar input{
    width: 145px;
    margin-top: 17px;
}

#user-profile-avatar .avatar-editor-zoom {
    width: 20px;
    height: 20px;
    margin-top: -5px;
}

#user-profile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#profile-section {
    /* border-color: var(--tr-secondary-color);
    border-style: dotted;
    border-width: 0.5px;
    border-radius: 20px; */
    display: flex;
    flex-direction: column;
}

#user-profile-data .content.col span {
    color: var(--tr-secondary-color);
    font-size: 1.8rem;
    white-space: nowrap;
    margin-top: 50px;
    margin-bottom: 20px;
}

.profile-section-wrapper {
    border-color: var(--tr-secondary-color);
    border-style: dotted;
    border-width: 0.5px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
}

.profile-section-wrapper .edit-field {
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
}

#user-profile-data .field {
    display: flex;
    padding: 20px;
    position: relative;
}

#user-profile-data .label {
    width: 180px;
    font-weight: 600;
    margin-right: 10px;
}

#user-profile-data .data {
    flex: 1;
}

#user-profile-name {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 20px;
}

#customer-profile .profile-title {
    color: var(--tr-secondary-color);
    font-size: 1.8rem;
    white-space: nowrap;
    margin-top: 50px;
    margin-bottom: 20px;
}

#customer-profile .profile-section {
    border-color: var(--tr-secondary-color);
    border-style: dotted;
    border-width: 0.5px;
    border-radius: 20px;
    margin-right: 30px;
    padding: 20px;
}

#customer-profile .deals-section {
    border-color: var(--tr-secondary-color);
    border-style: dotted;
    border-width: 0.5px;
    border-radius: 20px;
    margin-top: 30px;
    margin-right: 30px;
    padding: 20px;
}

.profile-buttons {
    margin: 30px;
}

#customer-profile .field {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
}

#customer-profile .label {
    width: 150px;
    font-weight: 600;
    margin-right: 10px;
}

#customer-profile .data {
    flex: 1;
}

#customer-profile .data .edit-field {
    position: relative;
    left: 20px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.subscription-type {
    margin: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 100vh;
    padding-bottom: 130px;
/*    height: 100%;*/
}

.subscription-name {
    color: var(--tr-secondary-color);
    font-size: 1.8rem;
    margin-top: 40px;
    text-align: center;
}

#subscription-description {
    color:black;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#subscription-description > div{
 display: inline-grid;
}

#subscription-description .field {
    display: inline-block;
}

.subscription-trial {
    color: var(--tr-secondary-color);
    display: inline-block;
}

.subscription-price {
    font-size: 2.5rem;
    font-weight: 600;
}

#subscription-footer {
    margin-bottom: 40px;
    text-align: center;
}

.subscription-action {
    margin-top: 15px;
    margin-bottom: 15px;
}

.subscription-current {
    color: var(--tr-secondary-color);
    font-weight: 600;
}

#subscription-footer button {
    margin-top: 20px;
}

.subscription-container {
    height: 90%;
}

#subscription-section {
    border-color: var(--tr-secondary-color);
    border-style: dotted;
    border-width: 0.5px;
    border-radius: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 240px;
    max-height: 100vh;
}

#subscription-section .icon img {
    height: 50px;
}

#subscription-section .icon {
    text-align: center;
    margin-top: 10px;
}

#subscribe-confirm-body {
    margin-top: 20px;
    font-size: 0.8rem;
}

#subscribe-confirm-terms-h {
    color: var(--tr-secondary-color);
}

#subscribe-confirm-terms {
    margin-top: 20px;
}

.tarology-modal {
    margin-top: 100px;
}

.tarot-header-container {
    height: 70px;
    top: 0px;
    position: fixed;
    background-color: var(--tr-primary-color);
    z-index: 1300;
    width: 100vw;
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.tarot-header-container .menu {
    margin-left: 20px;
    margin-top: 10px;
}

.tarot-search-mobile {
    display: none;        
}

.tarot-header-container .tarot-new-deal {
    display: none;        
}

.tarot-client-list-container {
    margin-top: 90px;
    width: 100%;
}

.p-accordion-tab {
    border-style: dashed;
    border-color: var(--tr-primary-color);
    border-width: 2px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px;
    /* background-color: var(--tr-light-color); 
    font-size: 1.2rem;
    font-weight: 600;*/
}

.p-accordion.p-component {
    width: calc(100% - 20px);
    margin-right: 20px;
}

.p-accordion-header::after {
    content: "\25B7";
    float: right;
    margin-top: -25px;
}

.p-accordion-header.p-highlight::after {
    content: "\25BD";
    float: right;
}

.p-accordion-header {
/*    border-style: solid;
    border-color: var(--tr-primary-color);
    border-width: 2px;
    margin-top: -2px;
    padding: 15px;
    background-color: var(--tr-light-color);*/
    font-size: 1.2rem;
    font-weight: 600;
}

.p-accordion-content {
    margin-top: 20px;
}

.p-accordion-content ul {
    font-size: 1.1rem;
    list-style: none;
}

.p-accordion-content li::before {
    content: "\2711";
    margin-right: 10px;
}

.p-accordion-content li {
    margin-top: 5px;
    margin-bottom: 5px;
}

.p-accordion-content li:hover {
    background-color: var(--tr-light-color);
}

.tarot-clients-client {
    font-size: 1.1rem;
    font-weight: 600;
    margin-left: 40px;
}

#buttons {
    margin-top: 30px;
    margin-bottom: 20px;
    margin-right: 10px;
    text-align: right;
}

.tarot-clients-client:hover {
    background-color: var(--tr-light-color);
}

.tarot-clients-client::before {
    content: "\2713";
    margin-right: 10px;
}

#tarot-select-modal {
    margin-top: 100px;
}

.tarot-client-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tarot-client-buttons div {
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.button-mobile-only {
    display: none;
}

.tarot-burger-menu {
    display: none;
}

@media screen and (max-width: 720px) {
    .wrapper {
        margin-top: 70px;
        height: 100vh;
        overflow-y: scroll;
/*        scroll-snap-type: y mandatory;*/
    }

    .tarot-deal-stage {
        padding-top: 20px;
        min-width: calc(100vw - 80px);
/*        height: calc(100vh - 100px);
        scroll-snap-align: start;*/
    }

    .tarot-deal-meta {
        padding-top: 20px;
        min-width: calc(100vw - 80px);
        /*min-height: calc(100vh - 100px);*/
        display: flex;
        flex-direction: column-reverse;
        /*scroll-snap-align: start;*/
    }

    .tarot-deal-explain {
        min-width: calc(100vw - 80px);
/*        min-height: calc(100vh - 100px);*/
/*        scroll-snap-align: start;*/
        padding-bottom: 130px;
        margin-left: 10px;
    }

    #work-area {
        min-width: unset;
/*        height: calc(100vh - 150px);*/
    }

    #work-area-extra {
        min-width: unset;
    }

    .content {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .tarot-content-expanded {
        margin-top: -40px;
/*        scroll-snap-type: y mandatory;*/
    }

    .tarot-content-client-expanded {
        margin-top: 0px;
/*        scroll-snap-type: y mandatory;*/
    }

    .tarot-client-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .tarot-client-icon-bed {
        margin-top: 0px;
        position: absolute;
        right: 0px;
    }

    .tarot-client-buttons {
        display: flex;
        flex-direction: column;
    }

    .tarot-client-buttons button {
        margin-bottom: 20px;
        width: 100%;
    }

    .tarot-client-deal {
        margin-right: 20px;
        margin-bottom: 50px;
    }

    .tarot-deal-container {
        display: flex;
        flex-direction: column-reverse;
/*        scroll-snap-type: y mandatory;*/
    }

    .tarot-deal-container .row {
        flex-wrap: nowrap;
    }

    .tarot-burger-menu {
        display: block;
        position: absolute;
        cursor: pointer;
        left: 20px;
    }

    .tarot-search-mobile {
        display: flex;
    }    

    .tarot-search-mobile .search-menu {
        margin-top: 15px;
        margin-right: 10px;
    }    

    .tarot-search-mobile .search-menu svg {
        width: 20px;
        height: 20px;
        color: white;
    }    

    .tarot-search-mobile .search-field {
        margin-top: 6px;
        margin-right: 10px;
    }    

    .tarot-header-container .tarot-new-deal {
        width: 40px;
        display: flex;
        color: white;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .tarot-header-container .tarot-new-deal svg {
        height: 40px;
        width: 40px;
    }  

    #tarot-meaning {
        font-size: 0.9rem;
    }

    #buttons {
        margin-top: -50px;
    }    

    #subscription-section {
        height: unset;
    }

    #subscription-description {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .subscription-container {
        margin-top: 20px;
        padding-bottom: 70px;
    }

    #user-profile {
        margin-top: 40px;
    }

    #user-profile-data {
        margin-top: 20px;
        margin-bottom: 40px;
    }

    #user-profile-data .field {
        flex-direction: column;
        padding-left: unset;
        padding-right: unset;
        padding-top: unset;
        padding-bottom: 20px;
    }

    #user-profile-data .content.col span {
        white-space: unset;
        margin-top: 20px;
    }

    #profile-section {
        padding: 20px;
        margin-right: -40px;
    }

    .profile-buttons {
        margin-top: -40px;
        padding-bottom: 70px;
    }

    .button-mobile-only {
        display: block;
    }

    #customer-profile {
        padding-bottom: 70px;
    }

    #customer-profile .field {
        flex-direction: column;
    }

    #customer-profile .profile-section {
        width: 100%;
    }

    #customer-profile .deals-section {
        width: 100%;
    }
    .p-accordion.p-component {
        width: 100%;
        margin-right: 20px;
        padding-bottom: 70px;
    }    

    .client-trash-bin svg {
        padding: 10px;
        width: 50px;
        height: 50px;
        margin: 10px;
        border-style: solid;
        border-width: 1px;
        border-radius: 50px;
        color: var(--tr-primary-color);
        border-color: var(--tr-primary-color);
    }

    .client-trash-bin {
        bottom: -70px;
        left: 0px;
    }
}

.splash-card {
    width: 500px;
    height: auto;
    padding: 20px;
    background-color: var(--tr-secondary-color);
    border-style: solid;
    border-width: 2px;
    border-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.splash-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.splash-logo {
    margin-top: -10px;
    width: 300px;
}

.splash-text {
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
}

.tarot-intro-title {
    color: white;
    font-size: 1.6rem;
    padding-top: 0.8rem;
}

.tarot-modal-p {
    margin-bottom: 0.4rem;
}

.tarot-intro-row {
    margin-top: 10px;
    font-size: 1.2rem;
    color: white;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
}

.tarot-intro-row input {
    width: 100%;
}

.tarot-intro-row input[type="radio"], input[type="checkbox"] {
    width: unset;
}

.tarot-intro-row .col {
    width: 100%;
}

.tarot-intro-button {
    border-color: white;
    border-width: 2px;
    width: 100%;
}

.tarot-intro-button:hover {
    border-color: white;
    border-width: 2px;
    width: 100%;
}

.tarot-intro-footer {
    color: white;
    margin-top: 0.4rem;
    margin-bottom: -10px;
}

.tarot-modal-input {
    background-color: var(--tr-light-color);
    width: 100%;
    border-width: 0px;
    border-radius: 3px;
}

.tarot-modal-select {
    background-color: var(--tr-light-color);
    width: 100%;
    font-size: 1rem;
    padding: 7px;
    border-width: 0px;
    border-radius: 3px;
}

.tarot-modal-p-error {
    margin-bottom: 1.5rem;
    font-weight: 800;
    color: tomato;
    line-height: 1.5rem;
}

.tarot-intro-link {
    cursor: pointer;
    font-weight: 600;
    color: var(--tr-light-color);
}

.tarot-context-menu {
    position: fixed;
    opacity: 1;
    pointer-events: auto;
    top: 500px;
    left: 500px;
    background-color: white;
    cursor: pointer;
    z-index: 1000;
    border-radius: 5px;
    border: black;
    border-style: solid;
    border-width: 1px;
}

.tarot-context-menu-item {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 15px;
    white-space: nowrap;   
}

.tarot-context-menu.hidden {
    display: none;
}

.tarot-context-menu-item:hover {
    background-color: var(--tr-light-color);
}

.tarot-context-menu-item span {
    margin-left: 7px;
}

.tarot-context-menu-item svg {
    margin-top: -5px;
}

.tarot-context-menu-divider {
    height: 1px;
    background-color: unset;
    border-bottom: black;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.tarot-extra-card-pic {
    width: 20% !important;
}

.tarot-global-extra-question {
    width: 100%;
}

.tarot-global-extra-row {
    width: 100%;
}

.tarot-extra-table-entity {
    position: unset;
}